<template>
  <div>
    <div class="grid grid-cols-2 gap-15 md:grid-cols-3 lg:gap-30 xl:grid-cols-4 2xl:grid-cols-5">
      <NuxtLink
        v-for="brand in filteredBrands"
        :key="brand.publicationCode"
        :to="brand.url"
        class="product group relative grid aspect-video place-content-center overflow-hidden rounded-2xl border outline-none ring-2 ring-transparent ring-offset-0 transition-all duration-700 ease-in-out hover:bg-black hover:ring-black hover:ring-offset-4 focus:bg-black focus:ring-black focus:ring-offset-4"
        :aria-label="brand.title"
        @click="trackClick(brand)"
      >
        <div class="flex h-[50px] items-center justify-center">
          <img v-if="brand.logo?.imageUrl" :src="brand.logo?.imageUrl" :alt="`${brand.title} logo`" class="product-logo z-10 h-full max-w-[70%] transition-colors group-hover:invert group-focus:invert md:max-w-[120px]" width="77" height="50" loading="lazy">
        </div>
        <img :src="`${brand.media?.hero?.imageUrl}?width=151&height=85&format=webp`" :srcset="`${brand.media?.hero?.imageUrl}?width=151&height=85&format=webp 151w, ${brand.media?.hero?.imageUrl}?width=285&height=160&format=webp 285w, ${brand.media?.hero?.imageUrl}?width=302&height=168&format=webp 302w, ${brand.media?.hero?.imageUrl}?width=448&height=253&format=webp 448w, ${brand.media?.hero?.imageUrl}?width=570&height=321&format=webp 570w`" sizes="(min-width:1280px) 320px, (min-width:1024px) 285px, 46vw" :alt="`${brand.title} hero`" class="absolute z-0 h-full w-full scale-[1.25] opacity-0" width="151" height="85" loading="lazy">
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ContentPageBrand } from '@aller/nuxt-allerservice-sdk/transformers';
import type { PropType } from 'vue';

const props = defineProps({
  filter: {
    type: Array as PropType<{ title: string; url: string }[]>,
  },
  topmenu: {
    type: Boolean,
    default: false,
  },
});

const trackClick = (item) => {
  useGTM().pushElement(props.topmenu ? 'click_top_menu_item' : 'click_promotion_element', {
    section_id: props.topmenu ? 'siteHeader' : 'content-brand-selector-grid',
    section_text: undefined,
    element_id: item.url,
    element_text: item.title,
    element_link: item.url,
  });
};

const allowedUrls = props.filter?.map((item) => convertToRelativeUrl(item.url)) || [];

const brands = useState<ContentPageBrand[]>('brands');
const filteredBrands = ref<ContentPageBrand[] | null>();

// As the brands state will only be present on client we move the data setting to mounted.
onMounted(() => {
  filteredBrands.value = brands?.value?.filter((brand) => allowedUrls.includes(brand.url as string)).filter((brand) => brand.media?.hero);
});

</script>

<style scoped>
.product:hover img,
.product:focus img {
  transform: scale(1);
  opacity: 1;
  transition:
    transform 4s ease-out,
    opacity 150ms ease-out;
}

img {
  transition:
    transform 150ms ease-in-out,
    opacity 150ms ease-out;
}
</style>
